<!-- VUETIFY2 - OK -->
<template>
    <base-layout datatableExcludedSize="160px">
        <template slot="header">
            <h1>Conversion en parts sociales<br/>"{{ exercice.libelle }}"</h1>
        </template>
        <template v-slot:main="{ datatableSize }">
            <ValidationObserver ref="formSO">
                <loading-screen ref="loadingComponent"/>
                <br/>
                <v-stepper fab :value="stepper.stepInProgress">
                    <v-stepper-header>
                        <v-stepper-step step="1" :complete="stepper.completeStep1">Saisie PB par salarié(e)
                        </v-stepper-step>
                        <v-divider/>
                        <v-stepper-step step="2" :complete="stepper.completeStep2">Saisie choix affectation PB
                        </v-stepper-step>
                        <v-divider/>
                        <v-stepper-step step="3" :complete="stepper.completeStep3">Souscriptions validées
                        </v-stepper-step>
                    </v-stepper-header>
                </v-stepper>
                <v-data-table
                    ref="affectation_pb_datatable"
                    :headers="table.headers"
                    :items="pbSalarieList"
                    :loading="isDataLoading"
                    :footer-props="footerProps"
                    fixed-header
                    :height="datatableSize"
                    class="elevation-1"
                >
                    <template v-slot:[`body.prepend`]>
                        <tr>
                            <td colspan="12" style="height: 4px">
                                <v-progress-linear v-if="souscriptionObliExerciceOuvert" slot="progress"
                                                   :value="progressBarValue"/>
                            </td>
                        </tr>
                    </template>
                    <template v-slot:item="{ item }">
                        <tr>
                            <td class="grey lighten-2">{{ item.salarie_name }}</td>
                            <td class="grey lighten-2">{{ item.salarie_numero_tiers }}</td>
                            <td v-if="souscriptionObliExerciceOuvert && !hasAccessParticipation">
                                <ValidationProvider :vid="'montant_pb_salarie' + item.id"
                                                    name="Montant de la souscription"
                                                    rules="currency|min_value:0"
                                                    v-slot="{ errors }">
                                    <v-formatted-number-text-field slot="input"
                                                                   v-model="item.montant_pb_salarie"
                                                                   prefixValue="€"
                                                                   labelValue="Montant de la souscription"
                                                                   :reverseValue="true"
                                                                   :isSingleLine="true"
                                                                   :isAutoFocus="false"
                                                                   :errors="errors"
                                                                   :hideDetails="true"
                                                                   specificClass="v-text-field-inline-datatable-editing"
                                                                   @change="onSaveMontantAffecte(item, $event)"/>
                                </ValidationProvider>
                            </td>
                            <td class="text-xs-center grey lighten-2" v-else>{{
                                    item.montant_pb_salarie | roundEuroFilter
                                }}
                            </td>
                            <td class="text-xs-center grey lighten-2">{{ item.montant_csg_crds | roundEuroFilter }}
                            </td>
                            <td class="text-xs-center grey lighten-2">{{ item.montant_pb_net | roundEuroFilter }}</td>
                            <td class="text-xs-center grey lighten-2"
                                v-if="item.montant_pb_affecte !== null && ! isNaN(item.taux_pb_affecte)">
                                {{ item.taux_pb_affecte | roundPercentFilter }}
                            </td>
                            <td class="text-xs-center grey lighten-2" v-else/>


                            <td v-show="item.id !== null && souscriptionObliExerciceAttente && !hasAccessParticipation">
                                <ValidationProvider :vid="'montant_pb_affecte' + item.id"
                                                    name="Montant de la souscription"
                                                    rules="currency|min_value:0"
                                                    v-slot="{ errors }">
                                    <v-formatted-number-text-field
                                        v-model="item.montant_pb_affecte"
                                        prefixValue="€"
                                        :reverseValue="true"
                                        :isSingleLine="true"
                                        :isAutoFocus="false"
                                        :disabledValue="false"
                                        labelValue="Montant de la souscription"
                                        :errors="errors"
                                        :hideDetails="true"
                                        specificClass="v-text-field-inline-datatable-editing"
                                        @change="onSavePbAffecte(item, $event)"/>
                                </ValidationProvider>
                            </td>
                            <td v-show="!(item.id !== null && souscriptionObliExerciceAttente && !hasAccessParticipation)"
                                class="text-xs-center grey lighten-2">
                                {{ item.montant_pb_affecte | roundEuroFilter }}
                            </td>
                            <td class="text-xs-center grey lighten-2">{{ item.nbPartsSociales | roundNumberFilter }}
                            </td>
                            <td class="text-xs-center grey lighten-2">{{ item.reliquatAffectation | roundEuroFilter }}
                            </td>
                            <td class="text-xs-center grey lighten-2">{{ item.montantNonAffecte | roundEuroFilter }}
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </ValidationObserver>
        </template>
        <template slot="footer">
            <v-row no-gutters>
                <!-- Petit bouton -->
                <v-col md="3" lg="3" xl="2">
                    <v-btn block dark color="primary" outlined @click="downloadExcel">
                        <v-icon dark left>{{ "$vuetify.icons.excel" }}</v-icon>
                        Télécharger
                    </v-btn>
                </v-col>
                <!-- Petit bouton-->
                <v-col md="3" lg="3" xl="2" class="ml-2"
                       v-if="(souscriptionObliExerciceAttente || souscriptionObliExerciceValide) && !hasAccessParticipation">
                    <v-btn block color="primary"
                           @click="downloadCertificatsPB">
                        <v-icon dark left>{{ "$vuetify.icons.download" }}</v-icon>
                        Certificats individuels PB
                    </v-btn>
                </v-col>
                <!-- Petit bouton-->
                <v-col md="3" lg="3" xl="2" class="ml-2" v-if="souscriptionObliExerciceValide">
                    <v-bulletins-obligatoires :capExerciceId="exercice.id"/>
                </v-col>
                <v-spacer/>
                <!-- Petit bouton -->
                <v-col cols="2" v-if="!souscriptionObliExerciceValide && !hasAccessParticipation">
                    <v-btn block color="secondary" @click="savePbSalarieList">
                        <v-icon dark left>{{ "$vuetify.icons.save" }}</v-icon>
                        Enregistrer
                    </v-btn>
                </v-col>
                <!-- Petit bouton -->
                <v-col md="3" lg="3" xl="2" class="ml-2" v-if="progressBarValue === 100 && souscriptionObliExerciceOuvert">
                    <v-btn block color="primary"
                           @click="validerSaisieMnt">
                        <v-icon dark left>{{ "$vuetify.icons.forward" }}</v-icon>
                        Valider les Montants
                    </v-btn>
                </v-col>
                <!-- Petit bouton-->
                <v-col md="3" lg="3" xl="2" class="ml-2" v-show="souscriptionObliExerciceAttente">
                    <v-validate-souscriptions-obligatoires :libelleExercice="exercice.libelle"
                                                           :categorieCapitalList="categorieCapitalList"
                                                           :exerciceId="exerciceId"
                                                           :dateAgExercice="exercice.date_ag_annuelle"
                                                           :pbSalarieListToPost="pbSalarieListToPost"
                                                           :dateSouscription="exercice.cap_exercice.montee_capital_par_affectation_date_mvt"
                                                           @update-date-souscription="exercice.cap_exercice.montee_capital_par_affectation_date_mvt = $event"
                                                           :categorieSouscription="exercice.cap_exercice.montee_capital_par_affectation_pb_categorie_cible"
                                                           @update-categorie-capital="exercice.cap_exercice.montee_capital_par_affectation_pb_categorie_cible = $event"
                                                           v-on:souscriptions-validees="getExercice"
                    />
                </v-col>
            </v-row>
        </template>
    </base-layout>
</template>

<script>
import _ from "lodash";
import internalApi from "@/api/internalApi";
import Utils from "@/utils";
import loadingScreen from "@/components/LoadingScreen.vue";
import VFormattedNumberTextField from "@/components/VFormattedNumberTextField.vue";
import constantes from "@/utils/constantes";
import BaseLayout from "@/components/BaseLayout";
import authService from "../../../services/authService";
import VValidateSouscriptionsObligatoires from "@/components/souscriptionsObligatoires/VValidateSouscriptionsObligatoires";
import VBulletinsObligatoires from "@/components/souscriptionsObligatoires/VBulletinsObligatoires";

/**
 * Vue affichant un tableau dans lequel renseigner les souscriptions obligatoire pour l'exercice
 * @displayName Capital - SouscriptionsObligatoires
 */
export default {
    components: {
        VValidateSouscriptionsObligatoires,
        loadingScreen,
        VFormattedNumberTextField,
        BaseLayout,
        VBulletinsObligatoires,
    },
    data() {
        return {
            table: {
                headers: [
                    {text: "Salarié(e)", sortable: true, value: "salarie_name"},
                    {text: "Numéro de tiers", sortable: false, value: "salarie_numero_tiers"},
                    {
                        text: "Montant brut de participation",
                        sortable: false,
                        value: "montant_pb_salarie",
                        align: "center"
                    },
                    {
                        text: "CSG-CRDS",
                        sortable: false,
                        value: "montant_csg_crds",
                        align: "center"
                    },
                    {
                        text: "Participation aux bénéfices nette",
                        sortable: false,
                        value: "montant_pb_net",
                        align: "center"
                    },
                    {
                        text: "% PB affecté",
                        sortable: false,
                        value: "taux_pb_affecte",
                        align: "center"
                    },
                    {
                        text: "Participation aux bénéfices affectée",
                        sortable: false,
                        value: "montant_pb_affecte",
                        align: "center"
                    },
                    {
                        text: "Nb de parts sociales",
                        sortable: true,
                        value: "nbPartsSociales",
                        align: "center"
                    },
                    {
                        text: "Reliquat affectation",
                        sortable: false,
                        value: "reliquatAffectation",
                        align: "center"
                    },
                    {
                        text: "Montant net non affecté",
                        sortable: false,
                        value: "montantNonAffecte",
                        align: "center"
                    }
                ]
            },
            pbSalarieList: [],
            progressBarValue: 0,
            footerProps: {
                itemsPerPageOptions: [10, 20, 50]
            },
            capCompany: undefined,
            exercice: {
                libelle: "", // Needed for the initial page rendering
                cap_exercice: {
                    etat_souscription_obligatoire: "",
                },
            },
            pbSalarieListToPost: [],
            categorieCapitalList: [],
            isDataLoading: true,
        };
    },
    computed: {
        // variables from store
        exerciceId() {
            return Number(this.$route.params.exerciceId);
        },

        // computed variables
        souscriptionObliExerciceOuvert() {
            return this.exercice.cap_exercice.etat_souscription_obligatoire === "OUVERT";
        },
        souscriptionObliExerciceAttente() {
            return this.exercice.cap_exercice.etat_souscription_obligatoire === "ATTENTE";
        },
        souscriptionObliExerciceValide() {
            return this.exercice.cap_exercice.etat_souscription_obligatoire === "VALIDE";
        },
        stepper() {
            let stepInProgress = 1;
            if (!this.hasAccessParticipation && this.souscriptionObliExerciceAttente) {
                stepInProgress = 2;
            } else if (this.hasAccessParticipation && this.souscriptionObliExerciceAttente) {
                stepInProgress = 3;
            } else if (this.souscriptionObliExerciceValide) {
                stepInProgress = 4
            }

            return {
                stepInProgress: stepInProgress,
                completeStep1: stepInProgress > 1,
                completeStep2: stepInProgress > 2,
                completeStep3: stepInProgress > 3
            };
        },
        hasAccessParticipation() {
            return authService.hasAccessParticipation();
        }
    },
    mounted() {
        this.isDataLoading = true;
        let required_promises = [];
        required_promises.push(this.getExercice());
        required_promises.push(this.getCapCompany());
        required_promises.push(this.getListeCategorieCapital());

        // Attendre d'avoir récupéré les exercices, les statuts et les catégories de capital
        Promise.all(required_promises).then(res => {
            this.getPbSalarieList().finally(() => {
                this.isDataLoading = false;
            });
        });
    },
    methods: {
        computePbSalarieListToPost() {
            const taux_pb_defaut = this.capCompany.souscription_valeur_engagement;
            this.pbSalarieListToPost = _.map(this.pbSalarieList, function (sal) {
                    //Pick only authorized field in POST body
                    if (_.isNil(sal.montant_pb_affecte)) {
                        sal.taux_pb_affecte = taux_pb_defaut;
                        sal.montant_pb_affecte = sal.montant_pb_net * sal.taux_pb_affecte / 100
                    }
                    return _.pick(sal, [
                        "id",
                        "salarie_id",
                        "associe_id",
                        "exercice_id",
                        "montant_pb_salarie",
                        "montant_pb_affecte",
                        "montant_pb_net"
                    ]);
                }
            );
        },
        // api calls
        /**
         * Récupère la liste des salariés pour leur affichage dans la datatable. S'il y a déjà des
         * informations les concernant, calcul pour chacun sa ligne attribuée
         */
        getPbSalarieList() {
            return internalApi.monteeCapital.getParticipationByExerciceId(this.exerciceId)
                .then(results => {
                    this.pbSalarieList = results;
                    this.computeValueProgressBar();
                    this.computePbSalarieListToPost();
                    return this.calculAllSouscriptionObligatoire(results);
                });
        },
        getListeCategorieCapital() {
            return internalApi.categorieCapital.getAll()
                .then(results => {
                    this.categorieCapitalList = _.sortBy(results, ["code"]);
                });
        },
        getExercice() {
            return internalApi.exercice.getById(this.exerciceId)
                .then(result => {
                    this.exercice = result;
                });
        },
        getCapCompany() {
            return internalApi.capCompany.getCurrentStatutCapCompany()
                .then(result => {
                    this.capCompany = result;
                });
        },
        /**
         * A partir du montant de la pb et du taux de pb affectés, calcul le montant
         * des autres colonnes de la table
         * @param pbSalarie Contient les informations de la ligne correspondant à un salarié
         */
        calculSouscriptionObligatoire(pbSalarie) {

            let res_promise;

            let montant_engagement_souscription = undefined;
            let taux_engagement_souscription = undefined;
            if (
                this.souscriptionObliExerciceOuvert && !this.hasAccessParticipation
            ) {
                // Actualiser à chaud à partir du montant brut de pb, et avec le % de PB affecté fixé à la valeur définie dans les status
                montant_engagement_souscription = null;
                taux_engagement_souscription = this.capCompany.souscription_valeur_engagement;
            } else {
                // Actualiser à chaud à partir du montant brut de pb, et du montant de PB affecté donné
                montant_engagement_souscription = pbSalarie.montant_pb_affecte;
                taux_engagement_souscription = pbSalarie.taux_pb_affecte;
            }

            if (_.isNumber(pbSalarie.montant_pb_salarie)) {
                let body = {
                    "montant_pb_brut": pbSalarie.montant_pb_salarie,
                    "montantEngagementSoucription": montant_engagement_souscription,
                    "tauxEngagementSoucription": taux_engagement_souscription,
                    "valeurSouscriptionPartSociale": this.capCompany.capital_montant_souscription_part,
                    "salarie_id": pbSalarie.salarie_id
                };
                res_promise = internalApi.monteeCapital.recalcul(
                    this.exerciceId,
                    body
                ).then(results => {
                    let pbSalarie = _.filter(this.pbSalarieList, ['salarie_id', results.salarie_id])[0];
                    pbSalarie = _.assign(pbSalarie, results);
                    //Let trigger list rendering by VueJS
                    this.pbSalarieList.sort();
                    this.computeValueProgressBar();
                });
            } else {
                res_promise = new Promise((resolve, reject) => {
                    resolve();
                });
            }

            return res_promise;
        },
        /**
         * Réalise le calcul de chaque ligne de la table à partir des données renseignées par
         * l'utilsiateur
         * @param pbSalarieList Les informations contenues dans chaque ligne
         */
        calculAllSouscriptionObligatoire(pbSalarieList) {
            return new Promise((resolve, reject) => {
                let promises = [];
                for (let pb of pbSalarieList) {
                    promises.push(this.calculSouscriptionObligatoire(pb));
                }
                Promise.all(promises).then(res => {
                    resolve(res);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        /**
         * Calcul la valeur de la barre de progression en fonction du nombre de lignes remplises
         */
        computeValueProgressBar() {
            let len = this.pbSalarieList.length;
            let fillPbSalarieNb = _.filter(this.pbSalarieList, function (sal) {
                return !_.isNil(sal.nbPartsSociales);
            }).length;
            this.progressBarValue = (fillPbSalarieNb / len) * 100;
        },
        onSavePbAffecte(props, montant_pb_affecte) {
            if (!Number.isNaN(montant_pb_affecte)) {
                props.montant_pb_affecte = montant_pb_affecte;
                this.calculSouscriptionObligatoire(props);
            }
        },
        /**
         * Lorsque l'utilisateur a fini de remplir le montant de pb , vérifie que le montant
         * est bien un nombre puis calcul les informations sur les autre colonnes
         * @param props La colonne contenant les informations à calculer
         * @param montant_pb_affecte Le montant servant à calculer les autres colonnes
         */
        onSaveMontantAffecte(props, montant_pb_affecte) {
            if (!Number.isNaN(montant_pb_affecte)) {
                props.montant_pb_affecte = props.montant_pb_net * this.capCompany.souscription_valeur_engagement / 100;
                this.calculSouscriptionObligatoire(props);
            }
        },
        /**
         * Met en forme les données de la datatable puis les enregistres
         */
        savePbSalarieList() {
            this.computePbSalarieListToPost();
            internalApi.monteeCapital.postParticipationByExerciceId(
                this.exerciceId,
                this.pbSalarieListToPost
            )
                .then(results => {
                    //replace api returns in pbSalarieList
                    let self = this;
                    _.forEach(results, function (res) {
                        _.chain(self.pbSalarieList)
                            .find(function (el) {
                                return el.salarie_id === res.salarie_id;
                            })
                            .merge(res)
                            .value();
                    });
                });
        },
        /**
         * Valide les montant saisis puis créer les certificats de participations
         */
        validerSaisieMnt() {
            this.$refs.loadingComponent.setLoading("Création des certificats de participation");
            this.computePbSalarieListToPost();
            internalApi.monteeCapital.validateMontantParticipationByExerciceId(
                this.exerciceId,
                this.pbSalarieListToPost
            )
                .then(() => {
                    let promise_salarie = this.getPbSalarieList();
                    let promise_exercice = this.getExercice(this.exerciceId);
                    Promise.all([promise_salarie, promise_exercice]).finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
                }).catch(error => {
                console.error(error);
                this.$refs.loadingComponent.resetLoading();
                return Promise.reject(error);
            });
        },
        /**
         * Télécharge les certificats de participation
         */
        downloadCertificatsPB() {
            this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement);
            internalApi.capExercice.getCertificatsParticipation(this.exerciceId)
                .then(response => {
                    this.$refs.loadingComponent.resetLoading();
                    Utils.downloadFile(response, "certificats_participation_benefice.pdf", Utils.pdfMimetype);
                })
                .catch(err => {
                    console.error(err);
                    this.$refs.loadingComponent.resetLoading();
                });

        },
        /**
         * Créer un export excel téléchargeable
         */
        downloadExcel() {
            let workbookProps = {
                Title: "Affectation des participations aux bénéfices",
                Subject: "Affectation des participations aux bénéfices",
                Author: "eZIScop",
                CreatedDate: Date.now()
            };
            let sheetTitle = "Affectation_PB";
            let fileTitle = `affectation_pb_${this.exercice.libelle}.xlsx`;
            Utils.writeXlsxFromDatatableRef(
                this.$refs.affectation_pb_datatable,
                workbookProps,
                sheetTitle,
                fileTitle
            );
        }
    }
};
</script>
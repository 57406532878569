<!-- VUETIFY2 - OK -->
<template>
    <dettes-list univers="capital"/>
</template>

<script>
    import DettesList from "@/components/DettesList";

    /**
     * Vue affichant la liste des dettes côté capital
     * @displayName Capital - CapDettes
     */
    export default {
        components: {
            DettesList
        }
    }
</script>
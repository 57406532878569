import defaultApi, {ACCEPTED_METHODS} from "../defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";
import {apiConstructor} from "../../index";

export default dettesApi;

function dettesApi() {
  const specificApi = {
    getAll: getAll(),
    genererSepa: genererSepa(),
    downloadDetail: downloadDetail(),
  };
  return Object.assign(specificApi, defaultApi(internalApiRoutes.dettes, [
    ACCEPTED_METHODS.getById,
  ]));
}

function getAll() {
  function getAllDettes(queryParams, headers) {
    let path = internalApiRoutes.dettes;
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getAllDettes;
}

function genererSepa() {
  function genererFichierSepa(body, queryParams, headers) {
    let path = internalApiRoutes.generationSepa();
    let postForPath = apiConstructor.post(path);
    return postForPath(body, queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return genererFichierSepa;
}

function downloadDetail() {
  function downloadDetail(dette_id, queryParams, headers) {
    let path = internalApiRoutes.downloadDetail(dette_id);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return downloadDetail;
}

<!-- VUETIFY2 - OK -->
<template>
    <dettes-list univers="participation"/>
</template>

<script>
    import DettesList from "@/components/DettesList";

    export default {
        components: {
            DettesList,
        },
    }
</script>